/* Scoped container for Contact Requests */
.contact-requests-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom:  2rem;
}

/* Table container styling */
.contact-requests-table-container {
  width: 80%;
  margin: 0 auto;
  overflow-x: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* General table styling */
.contact-requests-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.contact-requests-table th,
.contact-requests-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.contact-requests-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Row hover effect */
.contact-requests-table tr:hover {
  background-color: #f1f1f1;
}

/* Button styling */
.contact-requests-button {
  padding: 8px 12px;
  margin: 7rem;
  margin-top: 4rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-requests-button:hover {
  background-color: #0056b3;
}

.contact-requests-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}



/* Custom message container */
.contact-requests-heading {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .contact-requests-table {
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .contact-requests-table th, .contact-requests-table td {
    padding: 8px;
  }

  .contact-requests-button {
    padding: 6px 10px;
    font-size: 14px;
  }
}

/* Additional spacing
.mb-3 {
  margin-bottom: 15px;
}

.mt-3 {
  margin-top: 15px;
} */
