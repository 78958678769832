/* PrivateRoute.css */

/* Center the password form on the page */
.password-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 20px;
  }
  
  .password-form input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    max-width: 400px; /* Limit max width */
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .password-form button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .password-form button:hover {
    background-color: #0056b3;
  }
  
  /* Custom styling for the authenticated content */
  .authenticated-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  